<template>
    <div>
        <b-button class="mb-3" variant="light" @click="$router.back()">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <ui-card title="Create scopes profile" description="Enter the information requested below.">
            <create-profile-form ref="createProfile"/>
            <div class="text-end">
                <ui-button variant="primary" @click="register" :loading="loading">
                    Register
                </ui-button>
            </div>
        </ui-card>
    </div>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import CreateProfileForm from '@/components/profiles/CreateProfileForm.vue';

export default {
    components: { UiCard, UiButton, CreateProfileForm },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async register() {
            const formProfile = this.$refs['createProfile'];
                formProfile.v$.$validate();
                if (formProfile.v$.$error) {
                    const error = formProfile.v$.$errors[0];
                    const element = document.getElementById(error.$property);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                    else {
                        this.$toast.error("Please fill in all required fields.");
                    }
                    return;
                }
            try {
                this.loading = true;
                await this.$axios.post('/_/profiles/create', this.$refs.createProfile.formData);
                this.$toast.success('Profile created successfully');
                this.$router.push('/profiles');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>