<template>
    <div class="admin-scopes row">
        <div class="col-md-6 mb-4">
            <ui-input
                id="name"
                name="name"
                label="Profile name"
                type="text"
                v-model:value="formData.name"
                :field="v$.formData.name"
            />
        </div>
        <div class="col-md-6 mb-4">
            <ui-select
                id="role"
                name="role"
                class="w-100"
                label="Role"
                option-label="key"
                option-value="value"
                v-model:value="formData.role"
                :options="roles"
                :field="v$.formData.role"
            />
        </div>
        <div class="col-md-12 mb-4">
            <div>
                <h6 class="admin-scopes__subtitle">Scopes</h6>
                <p class="text-muted">Select the scopes related to this profile.</p>
            </div>
            <scopes-list id="scopes" @selectedScopes="handleScopes" />
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength, helpers } from '@vuelidate/validators';

import UiInput from '@/components/ui/Input.vue';
import UiSelect from '@/components/ui/Select.vue';
import ScopesList from '@/components/admins/scopes/ScopesList.vue';

import scopes from '@/data/scopes';

export default {
    name: 'CreateProfileForm',
    components: { UiSelect, UiInput, ScopesList },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            formData: {
                name: '',
                role: '',
                scopes: []
            },
            roles: [
                {
                    key: 'Admin',
                    value: 'admin',
                },
                {
                    key: 'User',
                    value: 'user',
                },
            ],
            tagValidations: {
                email: helpers.withMessage('Enter a valid email', email),
            },
            scopes: scopes,
            active: false,
            loading: false,
        };
    },
    validations() {
        return {
            formData: {
                name: {
                    required,
                    $autoDirty: true,
                },
                role: {
                    required,
                    $autoDirty: true,
                },
                scopes: {
                    required,
                    minLength: minLength(1),
                    $autoDirty: true,
                },
            }
        };
    },
    methods: {
        async validate() {
           return await this.v$.$validate();
        },
        handleScopes(scopes) {
            this.formData.scopes = scopes;
        }
    }
}
</script>

<style lang="scss">
.admin-scopes {
    &__subtitle {
        color: $general-black;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    &__checkbox {
        border: 2px solid $ecart-primary-400; 

        &:checked{
            background-color: $ecart-primary-400;
        }
    }
}
</style>